import React from 'react';
import Layout from 'modules/common/Layout';
import {useSelector} from 'react-redux';
import {Redirect} from '@reach/router';
import {useQuery} from '@apollo/client';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import SUBSCRIPTION from 'modules/subscription/graphql/subscription.graphql';
import ChangePlan from 'modules/subscription/change-subscription-plan';

const ChangeSubscription = () => {
  const auth = useSelector((state) => state.auth);
  const {data, loading} = useQuery(SUBSCRIPTION);

  const isAllowedToChangeSubscriptionPlan =
    data?.recurrentPayment?.status === 'ACTIVE' &&
    data?.recurrentPayment?.nextStatus !== 'CANCELED';

  if (!auth?.authenticated) {
    return <Redirect noThrow to="/auth/login" />;
  }
  if (loading) {
    return <LoadingIndicator />;
  }
  if (isAllowedToChangeSubscriptionPlan) {
    return <ChangePlan data={data?.recurrentPayment} />;
  }
  return <Redirect noThrow to="/subscription" />;
};

export default ChangeSubscription;
