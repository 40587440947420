import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useSelector} from 'react-redux';
import {navigate} from 'gatsby';
import axios from 'axios';
import Popup from 'modules/common/Popup';
import {Loader} from 'modules/subscription/styles';
import {Container, Button} from './styles';
import notification from 'core/libs/helpers/notification';
import CHANGEPAYPALPLAN from '../../graphql/changePaypalPlan.graphql';
import plansData from '../../content/subscriptionPlan';

const ChangePlan = ({
  statusState,
  exitPopup,
  selectedPlan,
  subscriptionData,
}) => {
  const auth = useSelector((state) => state);

  const [submitStatus, setSubmitStatus] = statusState;
  const mutation = useMutation(CHANGEPAYPALPLAN, {
    onCompleted: (e) => {
      window.open(e?.paypalChangeSubscriptionPackageMutation?.approveUrl);
      window.close();
      setSubmitStatus('idle');
    },
    onError: () => {
      notification({id: 'server_error_try_again'});
      setSubmitStatus('idle');
    },
  });

  const {title} = plansData.find((pln) => pln.planType === selectedPlan);
  const planExpiresAt = new Date(
    subscriptionData?.expiresAt,
  )?.toLocaleDateString('ar', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const paymentMethod = subscriptionData?.paymentMethod?.name;
  const recId = subscriptionData?.recurrentPaymentId;
  const userId = auth?.user?.profile?.data?.id;
  const apiKey = auth?.auth?.key;

  const handleSubmit = () => {
    if (paymentMethod === 'PAYPAL') {
      changeSubWithPaypal(exitPopup, mutation, selectedPlan, setSubmitStatus);

    } else {
      // This is used for all other paymentMethods (TAP, Amazon, Knet, etc.)
      changeSubPlanWithOtherServices(
        recId,
        selectedPlan,
        userId,
        apiKey,
        setSubmitStatus,
        exitPopup,
      );
    }

  };
  return (
    <Popup exitPopup={exitPopup} hideCloseIcon>
      <Container>
        <h3>هل أنت متأكد أنك تريد تغيير اشتراكك؟</h3>
        {paymentMethod === 'TAP' ? (
          <p>
            عند الموافقة، سيتم تغيير اشتراكك الحالي الى{' '}
            <strong>{title} </strong>
            بتاريخ {planExpiresAt}
          </p>
        ) : (
          <p>عند الموافقة سيتم تحويلك الى رابط خارجي لاستكمال العملية</p>
        )}
        <div>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={submitStatus === 'loading'}
          >
            {submitStatus === 'loading' ? <Loader /> : <span>نعم</span>}
          </Button>
          <Button variant="secondary" onClick={exitPopup}>
            لا
          </Button>
        </div>
      </Container>
    </Popup>
  );
};

export default ChangePlan;

const changeSubPlanWithOtherServices = async (
  recId,
  selectedPlan,
  userId,
  apiKey,
  setSubmitStatus,
  exitPopup,
) => {
  setSubmitStatus('loading');
  exitPopup();
  try {
    const res = await axios.post(
      `${process.env.GATSBY_API2}api/v1/billing/subscription/change-plan/?apikey=${apiKey}`,
      {
        plan_name: selectedPlan,
        subscription_id: recId,
      },
      {
        'Content-Type': 'application/json',
      },
    );
    notification({
      id: 'change_package',
      type: 'success',
    });
    navigate('/dashboard');
  } catch (error) {
    notification({id: 'server_error_try_again'});
    setSubmitStatus('idle');
  }
};

const changeSubWithPaypal = (
  exitPopup,
  mutation,
  selectedPlan,
  setSubmitStatus,
) => {
  const returnUrl = window.location.origin + '/dashboard';
  const [mutate] = mutation;
  setSubmitStatus('loading');
  exitPopup();
  mutate({variables: {planName: selectedPlan, returnUrl}});
};
