import styled from 'styled-components';

const blue = '#357EDD';
const darkBlue = '#00449e';

export const Container = styled.div`
  width: 29rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  h3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  div {
    display: flex;
    width: 100%;
  }
  p {
    line-height: 2rem;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 0;
    font-size: 0.9rem;
  }
`;
export const Button = styled.button`
  background-color: ${({variant}) => (variant === 'primary' ? blue : '#fff')};
  color: ${({variant}) => (variant === 'primary' ? '#fff' : blue)};
  border-radius: 2px;
  border: 2px solid ${blue};
  padding: 0.87rem;
  width: 12rem;
  margin: 2rem 0.5rem;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 100%;
  }
  &:hover {
    background-color: ${({variant}) =>
      variant === 'primary' ? darkBlue : '#fff'};
    border: 2px solid ${darkBlue};
    color: ${({variant}) => (variant === 'primary' ? '#fff' : darkBlue)};
  }
`;
