import React, {useState} from 'react';
import {
  PaymentWrapper,
  DescriptionWrapper,
  ButtonWrapper,
  Button,
  CouponWrapper,
  ButtonCoupon,
  SubmitWrapper,
  Loader,
  Line,
} from './styles';
import SubHeader from './components/Header';
import StepHeader from './components/StepHeader';
import SubscriptionPackages from './components/SubscriptionPackages';
import Container from './components/common/index';
import ChosenPlan from './components/ChosenPlan';
import SubscriptionDescription from './components/SubscriptionDescription';
import ChangePlan from './components/ChangePlan';
import WaitingMessage from 'modules/common/WaitingMessage';

const ChangeSubPlan = ({data}) => {
  const currentUserPlan = data?.subscriptionPlan?.planName;
  const [submitStatus, setSubmitStatus] = useState('idle');
  const [selectedPlan, setSelectedPlan] = useState(currentUserPlan);
  const [showPopup, setShowPopup] = useState(false);

  if (submitStatus === 'loading') {
    return (
      <WaitingMessage>
        <h3> المرجو الانتظار قليلا بينما نقوم بتغيير باقة اشتراككم .</h3>
      </WaitingMessage>
    );
  }
  return (
    <>
      <SubHeader />
      <StepHeader step="1" message_id="choose_new_package" />
      <SubscriptionPackages
        radioState={[selectedPlan, setSelectedPlan]}
        currency="USD"
        isActiveSubscription
      />
      <Container>
        <Line />
        <DescriptionWrapper>
          <ChosenPlan plan={selectedPlan} currency="USD" />
        </DescriptionWrapper>
        <SubmitWrapper>
          <ButtonWrapper>
            <Button
              type="submit"
              onClick={() => setShowPopup(true)}
              disabled={selectedPlan === currentUserPlan}
            >
              إنهاء العملية
            </Button>
          </ButtonWrapper>
        </SubmitWrapper>
        {showPopup && (
          <ChangePlan
            statusState={[submitStatus, setSubmitStatus]}
            exitPopup={() => setShowPopup(false)}
            selectedPlan={selectedPlan}
            subscriptionData={data}
          />
        )}
      </Container>
    </>
  );
};

export default ChangeSubPlan;
